import { defineStore } from "pinia";

const STORAGE_KEY = "SystemHealthHiddenTags";

const useHiddenTagsStore = defineStore("hiddenTags", {
  state: () => ({
    tagVisibilityState: true,
  }),
  actions: {
    populateFromStorage() {
        let storageValue = sessionStorage.getItem(STORAGE_KEY);
        if (storageValue === "undefined")
        {
          sessionStorage.setItem(STORAGE_KEY, JSON.stringify(true));
        }

        this.tagVisibilityState = JSON.parse(sessionStorage.getItem(STORAGE_KEY));

    }
  },
});

const watchStore = useHiddenTagsStore();
watchStore.$subscribe((_, state) => {
  sessionStorage.setItem(STORAGE_KEY, JSON.stringify(state.tagVisibilityState));
})

export default useHiddenTagsStore;

export { useHiddenTagsStore };
