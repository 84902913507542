import { defineStore } from "pinia";

const STORAGE_KEY = "SystemHealthColumnOrder";

const useColumnOrderStore = defineStore("columnOrder", {
  state: () => ({
    sortKey: "ID",
    sortAscending: true,
  }),
  actions: {
    populateFromStorage() {
        let storageValue = sessionStorage.getItem(STORAGE_KEY);
        if (storageValue === "undefined" || storageValue == null)
        {
            sessionStorage.setItem(STORAGE_KEY, JSON.stringify({
                sortKey: "ID",
                sortAscending: true,
            }));
        }

        let record = JSON.parse(sessionStorage.getItem(STORAGE_KEY));
        this.sortKey = record.sortKey;
        this.sortAscending = record.sortAscending;
    }
  },
});

const watchStore = useColumnOrderStore();
watchStore.$subscribe((_, state) => {
    sessionStorage.setItem(STORAGE_KEY, JSON.stringify(state));
})

export default useColumnOrderStore;

export { useColumnOrderStore };
