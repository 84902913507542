import { defineStore } from "pinia";

const STORAGE_KEY = "SystemHealthFilterSitesByTags";
const STORAGE_TAGCONDITION_KEY = "SystemHealthTagConditionAND";

const useFilterSitesByTagsStore = defineStore("filterSitesByTags", {
  state: () => ({
    isTagFilterAnd: true,
    selectedTags: [],
    selectedTagsHiddenOverride: false
  }),
  actions: {
    populateFromStorage() {
        // For Selected Tags
        let storageValue = sessionStorage.getItem(STORAGE_KEY);
        if (storageValue === "undefined" || storageValue == null)
        {
            sessionStorage.setItem(STORAGE_KEY, JSON.stringify([]));
        }
        this.selectedTags = JSON.parse(sessionStorage.getItem(STORAGE_KEY));

        // For Tag Filtering
        let storageTagConditionValue = sessionStorage.getItem(STORAGE_TAGCONDITION_KEY);
        if (storageTagConditionValue === "undefined" || storageTagConditionValue == null)
        {
            sessionStorage.setItem(STORAGE_TAGCONDITION_KEY, JSON.stringify(true));
        }
        this.isTagFilterAnd = JSON.parse(sessionStorage.getItem(STORAGE_TAGCONDITION_KEY));
    },
    selectTag(value) {
        const index = this.selectedTags.findIndex((element) => element === value);
        if (index === -1) {
            this.selectedTags.push(value);
        }
    },
    unselectTag(value) {
        const index = this.selectedTags.findIndex((element) => element === value);
        if (index !== -1) {
            this.selectedTags.splice(index, 1);
        }
    },
    clearTags() {
        this.selectedTags = [];
        sessionStorage.setItem(STORAGE_KEY, JSON.stringify([]));
        this.populateFromStorage();
    }
    
  },
});

const watchStore = useFilterSitesByTagsStore();
watchStore.$subscribe((_, state) => {
    sessionStorage.setItem(STORAGE_KEY, JSON.stringify(state.selectedTags));
    sessionStorage.setItem(STORAGE_TAGCONDITION_KEY, JSON.stringify(state.isTagFilterAnd));
})

export default useFilterSitesByTagsStore;

export { useFilterSitesByTagsStore };
