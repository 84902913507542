<template>
  <th @click="setSortKey()" class="th-sorter">
    <i v-if="sortKey !== sortBy" class="bi bi-sort-down-alt opacity-50"></i>
    <i v-else-if="sortAscending" class="bi bi-sort-down-alt"></i>
    <i v-else class="bi bi-sort-down"></i>
    <slot></slot>
  </th>
</template>

<style scoped>
.th-sorter {
  cursor: pointer;
  user-select: none;
}

/* Slightly reposition the icon to pair seamlessly with .bi-sort-down-alt */
.bi-sort-down {
  position: relative;
  top: 1px;
}
</style>

<script>
export default {
  name: "ThSortable",
  props: ["sortKey", "sortAscending", "sortBy"],
  methods: {
    setSortKey() {
      if (this.sortKey === this.sortBy) {
        this.$emit("update:sortAscending", !this.sortAscending);
      } else {
        this.$emit("update:sortKey", this.sortBy);
        this.$emit("update:sortAscending", true);
      }
    },
  },
};
</script>
