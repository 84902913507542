<template>
  <div class="m-3">
    <sites-header>
      <h4 class="text-center">Sites</h4>
    </sites-header>

    <table class="table table-hover">
      <thead>
        <tr>
          <th class="pt-0">
            <!-- Site search -->
            <input
              v-model="siteFilter"
              type="search"
              class="form-control"
              placeholder="Site name..."
            />
            <button type="button" class="btn btn-primary" style="margin-bottom: -8px" v-if="siteColumnHidden" @click="siteColumnHidden=false">
                <i class="bi bi-unlock"></i> Show Site Column
            </button>
            <button type="button" class="btn btn-secondary" style="margin-bottom: -8px" v-if="!siteColumnHidden" @click="siteColumnHidden=true">
                <i class="bi bi-lock"></i> Hide Site Column
            </button>
          </th>

          <th class="pt-0 options-button" colspan="3">
            <p>              
              <button class="btn btn-secondary form-control" style="margin-bottom: -8px; height: 75px" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                <i class="bi bi-wrench"></i> Other options
              </button>
            </p>
            <div class="collapse" id="collapseExample">

              <div class="mb-3">
                <div class="form-check form-switch">
                  <input class="form-check-input" type="checkbox" role="switch" id="showHiddenSites" @change="this.hiddenSiteStore.sitesHiddenOverride=!this.hiddenSiteStore.sitesHiddenOverride">
                  <label class="form-check-label" for="showHiddenSites">Show Hidden Sites</label>
                </div>
              </div>
              <div class="mb-3">
                <div class="form-check form-switch">
                  <input class="form-check-input" type="checkbox" role="switch" id="showTags" v-model="this.hiddenTagsStore.tagVisibilityState">
                  <label class="form-check-label" for="showTags">Show Tags</label>
                </div>
              </div>
              <div class="mb-3">
                <label for="exampleInputEmail1" class="form-label">Percentage Threshold</label>
                <input
                v-model="this.percentageThresholdStore.percentageValue"
                type="number"
                class="form-control"
                />
                <div class="form-text">This will highlight rows with statuses at or below the number specified.</div>
              </div> 
              <label for="exampleInputEmail1" class="form-label">Tag Filter</label>
              <div class="mb-3">
                <span>
                  <p class="tag-filter-left" style="padding-right: 8px;">OR</p>
                    <div class="form-check form-switch tag-filter-left">
                      <input class="form-check-input logic-toggle-input" type="checkbox" role="switch" id="flexSwitchCheckDefault2" v-model="this.filterSitesByTagsStore.isTagFilterAnd">
                    </div>
                  <p class="tag-filter-left">AND</p>
                  
                  <span class="ms-3 badge bg-primary" v-if="this.filterSitesByTagsStore.selectedTags.length > 0" @click="this.filterSitesByTagsStore.clearTags()">Clear</span>
                </span>
                <div style="clear:both;"></div>
                
                <ul class="list-group">
                  <li class="list-group-item d-flex justify-content-between align-items-start" v-for="tag in this.allTags.sort()">
                    <span class="badge badge-primary badge-pill">
                      <input type="checkbox" :id="'tag_' + tag" aria-label="Checkbox for following text input" :checked="this.filterSitesByTagsStore.selectedTags.includes(tag)" @change="changedTagCheck($event, tag)" />  
                    </span>
                    <label class="ms-2 me-auto" :for="'tag_' + tag">
                      <div class="fw-bold">{{tag}}</div>
                    </label>
                    
                  </li>
                </ul>
              </div> 
              
            </div>

          </th>

          <th colspan="6" class="table-dark text-center">
            Path Status &ndash; Perf. Rating
          </th>

          <th
            rowspan="2"
            class="table-dark"
            style="border-bottom-color: currentColor"
          >
            <info-text desc="% of heads with a maximum temperature below 85 °C">
              % Heads Below<br />Temp Limit
            </info-text>
          </th>

          <th
            rowspan="2"
            class="table-dark"
            style="border-bottom-color: currentColor"
          >
            Rack Max<br />Temp (C)
          </th>

          <th
            rowspan="2"
            class="table-dark actionsColumn"
            style="border-bottom-color: currentColor">
            Actions
          </th>
        </tr>
        <tr class="table-dark">
          <!-- Site -->
          <th-sortable
            sort-by="ID"
            v-model:sortKey="this.columnOrderStore.sortKey"
            v-model:sortAscending="this.columnOrderStore.sortAscending"
          >
            Site
          </th-sortable>

          <!-- System -->
          <th>System</th>

          <th-sortable
            sort-by="PathMetrics.SoftwareVersion"
            v-model:sortKey="this.columnOrderStore.sortKey"
            v-model:sortAscending="this.columnOrderStore.sortAscending"
          >
            Version
          </th-sortable>

          <!-- Updated -->
          <th-sortable
            sort-by="DateStamp"
            v-model:sortKey="this.columnOrderStore.sortKey"
            v-model:sortAscending="this.columnOrderStore.sortAscending"
          >
            Updated
          </th-sortable>

          <!-- Status -->
          <th-sortable
            sort-by="PathMetrics.PassingPercent"
            v-model:sortKey="this.columnOrderStore.sortKey"
            v-model:sortAscending="this.columnOrderStore.sortAscending"
          >
            Status
          </th-sortable>

          <th>1</th>
          <th>2</th>
          <th>3</th>
          <th>4</th>
          <th>5</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(entry, index) in sortedOverview"
          :key="entry.ID"
          :class="{ 'table-danger': (entry.PathMetrics != null && entry.PathMetrics.PassingPercent <= this.percentageThresholdStore.percentageValue) }"
        >
          <td>
            <i class="bi bi-eye-slash" v-if="(this.hiddenSiteStore.sitesHidden.indexOf(entry.ID) !== -1)"></i>
            
            <span v-if="siteColumnHidden" class="placeholder w-100"></span>
            <router-link
              v-else
              :to="{
                name: 'Site Data',
                params: {
                  siteId: entry.ID,
                  date: entry.DateStamp,
                },
              }"
              class="link-dark" 
            >
              {{ entry.ID }}
            </router-link>

            <!-- Badges -->
            <template v-if="this.hiddenTagsStore.tagVisibilityState">
            <br/>
            
            <span class="badge rounded-pill bg-info text-dark" v-for="tag in getSiteTags(entry.Tags)" :key="tag">
              <span v-if="siteColumnHidden" v-for="value in tag.length" :key="value" class="placeholder">&nbsp;</span>
              <span v-else >{{ tag }}</span>
            </span>
            <i style="padding-left: .5em;" class="bi bi-pencil-square" data-bs-toggle="modal" :data-bs-target="('#staticBackdrop'+ index)"></i>
            

            <!-- Modal -->
            <div class="modal fade" :id="('staticBackdrop'+ index)" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title">Tags - {{ entry.ID }}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div class="modal-body">
                    <table class="table">
                      <thead>
                        <tr>
                          <th scope="col" style="width: 80%;">Tag</th>
                          <th scope="col" style="width: 20%;">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="tag, index in getSiteTags(entry.Tags)" :key="index">
                          <td>
                            <p>{{tag}}</p>
                          </td>
                          <td>
                            <button type="button" class="btn btn-outline-dark" @click="this.removeSiteTag(entry.ID, index)"><i class="bi bi-trash"></i></button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <hr/>
                    <div class="input-group mb-3">
                      <input type="text" class="form-control" v-model="this.tagValue" placeholder="Tag" aria-label="Tag" aria-describedby="tagInput" />
                      <button class="btn btn-outline-primary" type="button" id="tagInput"  @click="this.addSiteTag(entry.ID, this.tagValue)">Add</button>
                    </div>
                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                  </div>
                </div>
              </div>
            </div>
          </template>

          </td>
          <td>
            <template v-if="entry.PathMetrics && Object.keys(entry.PathMetrics).includes('SystemType') && !isNaN(parseInt(entry.PathMetrics.SystemType))">
              <!-- Pre-8.8 release, the numeric values were sent, but were not brought up to the frontend. -->
              <template v-if="parseInt(entry.PathMetrics.SystemType) === 0">
                Process Control
              </template>

              <template v-if="parseInt(entry.PathMetrics.SystemType) === 1">
                Combustion Balance
              </template>
              
            </template>
            <template v-if="entry.PathMetrics && Object.keys(entry.PathMetrics).includes('SystemType') && isNaN(parseInt(entry.PathMetrics.SystemType))">
              <!-- These are for systems after 8.8 was installed. The string value will be present from the enum type in the backend. -->
              {{ entry.PathMetrics.SystemType }}
            </template>
          </td>
          <td>
            <template v-if="entry.PathMetrics && Object.keys(entry.PathMetrics).includes('SoftwareVersion')">
              {{ entry.PathMetrics.SoftwareVersion }}
            </template>
          </td>
          <td>{{ getMonthAndDayOfYear(entry.DateStamp) }}</td>
          <td-status
            :success="
              entry.PathMetrics && entry.PathMetrics.PassingPercent > 90
            "
            :display="
              entry.PathMetrics && `${entry.PathMetrics.PassingPercent}%`
            "
            success-class="text-success"
            fail-class="text-danger"
          />
          <td class="text-success">
            <template v-if="entry.PathMetrics">
              {{ entry.PathMetrics.StatusCounts[1] }}
            </template>
          </td>
          <td class="text-success">
            <template v-if="entry.PathMetrics">
              {{ entry.PathMetrics.StatusCounts[2] }}
            </template>
          </td>
          <td class="text-danger">
            <template v-if="entry.PathMetrics">
              {{ entry.PathMetrics.StatusCounts[3] }}
            </template>
          </td>
          <td class="text-danger">
            <template v-if="entry.PathMetrics">
              {{ entry.PathMetrics.StatusCounts[4] }}
            </template>
          </td>
          <td class="text-danger">
            <template v-if="entry.PathMetrics">
              {{ entry.PathMetrics.StatusCounts[5] }}
            </template>
          </td>
          <td-status
            :success="
              entry.PathMetrics &&
                entry.PathMetrics.HeadsBelowTempLimitPercent === 100
            "
            :display="
              entry.PathMetrics &&
                `${entry.PathMetrics.HeadsBelowTempLimitPercent}%`
            "
            success-class="text-success"
            fail-class="text-danger"
          />
          <td-status
            :success="entry.PathMetrics && entry.PathMetrics.RackMaxTemp < 40"
            :display="
              entry.PathMetrics && round(entry.PathMetrics.RackMaxTemp, 1)
            "
            success-class="text-success"
            fail-class="text-danger"
          />
          <td class="actionsColumn">
            <div class="dropdown">
              <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <a class="dropdown-item" @click="this.hiddenSiteStore.addHiddenSite(entry.ID)" v-if="this.hiddenSiteStore.sitesHidden.indexOf(entry.ID) === -1"><i class="bi bi-eye-slash"></i> Hide</a>
                <a class="dropdown-item" @click="this.hiddenSiteStore.removeHiddenState(entry.ID)" v-if="this.hiddenSiteStore.sitesHidden.indexOf(entry.ID) !== -1"><i class="bi bi-eye-slash"></i> Unhide</a>
                <a class="dropdown-item" @click="deleteSite(entry.ID)"><i class="bi bi-trash"></i> Delete Site & Records</a>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import store from "@/store";
import { round } from "@/helpers";

import ThSortable from "@/components/ThSortable.vue";
import SitesHeader from "@/components/SitesHeader.vue";
import TdStatus from "@/components/TdStatus.vue";
import InfoText from "@/components/InfoText.vue";

import { useHiddenSiteStore } from "@/stores/hiddenSiteStore";
import { useHiddenTagsStore } from "@/stores/hiddenTagsStore";
import { useColumnOrderStore } from "@/stores/columnOrderStore";
import { useFilterSitesByTagsStore } from "@/stores/tagFilterStore";
import { usePercentageThresholdStore } from "@/stores/percentageThresholdStore";

export default {
  name: "Sites",
  components: { ThSortable, SitesHeader, TdStatus, InfoText },
  data() {
    return {
      overview: [],
      siteFilter: "",
      siteColumnHidden: true,
      loadingSearchParameters: false,
      tagValue: "",
      allTags: []
    };
  },
  created() {
    document.title = "System Health";

    this.loadSearchParameters();
    // Load overview
    store.getOverview().then((overview) => (this.overview = overview));

    this.hiddenSiteStore.populateFromStorage();
    this.hiddenTagsStore.populateFromStorage();
    this.columnOrderStore.populateFromStorage();
    this.percentageThresholdStore.populateFromStorage();
    this.filterSitesByTagsStore.populateFromStorage();
  },
  setup() {
    const hiddenSiteStore = useHiddenSiteStore();
    const hiddenTagsStore = useHiddenTagsStore();
    const columnOrderStore = useColumnOrderStore();
    const percentageThresholdStore = usePercentageThresholdStore();
    const filterSitesByTagsStore = useFilterSitesByTagsStore();

    return {
      hiddenSiteStore,
      hiddenTagsStore,
      columnOrderStore,
      percentageThresholdStore,
      filterSitesByTagsStore
    };
  }, 
  methods: {
    round,
    hasFilterTags() {
      return this.filterSitesByTagsStore.selectedTags && this.filterSitesByTagsStore.selectedTags.length > 0;
    },
    saveSearchParameters() {
      if (!this.loadingSearchParameters)
        store.searchParameters = {
          siteFilter: this.siteFilter,
        };
    },
    loadSearchParameters() {
      if (store.searchParameters) {
        this.loadingSearchParameters = true;
        this.siteFilter = store.searchParameters.siteFilter;

        this.loadingSearchParameters = false;
      }
    },
    deleteSite(siteId){
      store.deleteSite(siteId).then(() => {
        window.location.reload();
      });
      
    },
    // Exclude year from the output unless it's different from the current year
    getMonthAndDayOfYear(isoDate) {
      const date = new Date(isoDate);
      const now = new Date();

      if (now.getFullYear() === date.getUTCFullYear())
        return date.toLocaleString("default", {
          timeZone: "UTC",
          month: "numeric",
          day: "numeric",
        });

      return date.toLocaleString("default", {
        timeZone: "UTC",
        month: "numeric",
        day: "numeric",
        year: "numeric",
      });
    },
    getSiteTags(overviewTags){
      let tags = [];

      if(!overviewTags) {
        return tags;
      }

      overviewTags.forEach((tag) => {
        if(tag.S) {
          tags.push(tag.S);
        }
      });

      return tags;
    },
    addSiteTag(siteId, tagValue) {
      store.addSiteTag(siteId, tagValue).then(() => {
        store.getOverview().then((overview) => {
          this.overview = overview;
          this.tagValue = "";
        });
      });
    },
    removeSiteTag(siteId, tagIndex) {
      store.removeSiteTag(siteId, tagIndex).then(() => {
        store.getOverview().then((overview) => (this.overview = overview));
      });
      
    },
    getAllSiteTags() {
      return this.filterSitesByTagsStore.allTags;
    },
    // getSelectedSiteTags() {
    //   return this.filterSitesByTagsStore.selectedTags;
    // },
    changedTagCheck(event, tag) {
      if (event.srcElement.checked === true)
      {
        this.filterSitesByTagsStore.selectTag(tag);
      }

      if (event.srcElement.checked === false)
      {
        this.filterSitesByTagsStore.unselectTag(tag);
      }
    }
  },
  computed: {
    filteredOverview() {
      if (this.siteFilter) {
        const lowerCaseSiteFilter = this.siteFilter.toLowerCase();

        return this.overview.filter((x) =>
          x.ID.toLowerCase().includes(lowerCaseSiteFilter)
        );
      }

      let overviewFields = this.overview;

      // Remove hidden sites. 
      if (!this.hiddenSiteStore.sitesHiddenOverride && this.hiddenSiteStore.sitesHidden && this.hiddenSiteStore.sitesHidden.length) {
        overviewFields = overviewFields.filter((x) => {
          if (this.hiddenSiteStore.sitesHidden.indexOf(x.ID) !== -1)
          {
            // found so drop it from the returned array. 
            return false;
          }
          return true;
        });
      }

      // Remove Non-matching filter sites
      if (this.filterSitesByTagsStore.isTagFilterAnd == false && this.hasFilterTags()) {
        // Look at all the elments in the overview Field. 
        overviewFields = overviewFields.filter((x) => {
          // debugger;
          let keepElement = false;

          // Check that the element has a tag attribute and it isn't empty
          if (Object.keys(x).includes('Tags') && x.Tags.length > 0) {

            // Look through the tags and handle that the tags are in an object with "S" as a key. 
            x.Tags.forEach((tag) => {
              let tagValue = tag.S;

              if (this.filterSitesByTagsStore.selectedTags.includes(tagValue)) {
                keepElement = true;
              }
            });
          }
          return keepElement;
        });
      }

      if (this.filterSitesByTagsStore.isTagFilterAnd == true && this.hasFilterTags()) {
        // Look at all the elments in the overview Field. 
        overviewFields = overviewFields.filter((x) => {
          // debugger;
          let keepElement = false;

          // Check that the element has a tag attribute and it isn't empty
          if (Object.keys(x).includes('Tags') && x.Tags.length > 0) {

            // Keep going here to figure out what this is doing...
            const allTags = Object.values(x.Tags).map(x=> x.S);
            if(this.filterSitesByTagsStore.selectedTags.every(tag => allTags.includes(tag))) {
            // if(x.Tags.every(tag => this.filterSitesByTagsStore.selectedTags.includes(tag.S))) {
              keepElement = true;
              // debugger;
            }

          }
          return keepElement;
        });
      }

      return overviewFields;
    },
    sortedOverview() {
      return this.filteredOverview.sort((a, b) => {
        const keys = this.columnOrderStore.sortKey.split(".");

        let A = a,
          B = b;

        // Traverse down a chain of keys to obtain the desired values
        keys.forEach((key) => {
          A = A[key] || "";
          B = B[key] || "";
        });

        if (this.columnOrderStore.sortAscending) {
          if (A.localeCompare) return A.localeCompare(B);
          return A - B;
        }

        if (B.localeCompare) return B.localeCompare(A);
        return B - A;
      });
    },
  },
  watch: {
    siteFilter() {
      this.saveSearchParameters();
    },
    overview() {
      this.overview.forEach((record) => {
        if (Object.keys(record).includes('Tags') && record.Tags.length > 0) 
        {
          record.Tags.forEach((tag) => {
            const index = this.allTags.findIndex((element) => element === tag.S);
            if (index === -1) {
                this.allTags.push(tag.S);     
            } 
          })
        }
      })
      
    }
  },
};
</script>

<style scoped>
.dropdown > .btn {
  padding: 0rem .3rem;
}
.actionsColumn {
  text-align: center;
  width: 0px;
}
.options-button {
  margin-bottom: 0;
  padding-bottom: 0;
}

.table-initial {
  background-color: rgba(10, 10, 10, .4);
}

.list-group{
    max-height: 200px;
    margin-bottom: 10px;
    overflow:scroll;
    -webkit-overflow-scrolling: touch;
}

.logic-toggle-input {
  background-color: #0d6efd;
  border-color: #0d6efd;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.logic-toggle-input:checked {
    background-color: #0d6efd;
    border-color: #0d6efd;
}

.tag-filter-left {
  float: left;
}

.form-switch .tag-filter-left {
  margin: 0;
  padding: 0;
}

.placeholder:hover {
  cursor: default;
} 

</style>