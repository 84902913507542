import { defineStore } from "pinia";

const STORAGE_KEY = "SystemHealthHiddenItems";

const useHiddenSiteStore = defineStore("hiddenSites", {
  state: () => ({
    sitesHidden: [],
    sitesHiddenOverride: false,
  }),
  actions: {
    populateFromStorage() {
      const lsSitesHidden = sessionStorage.getItem(STORAGE_KEY);

      if (lsSitesHidden)
      {
        this.sitesHidden = JSON.parse(lsSitesHidden);
      }
    },
    addHiddenSite(siteId) {
      this.sitesHidden.push(siteId);
      this.updateStorage();
    },
    removeHiddenState(siteId) {
      let siteIndex = this.sitesHidden.indexOf(siteId);
      this.sitesHidden.splice(siteIndex, 1);
      this.updateStorage();
    },
    updateStorage() {
      const encodedSitesHidden = JSON.stringify(this.sitesHidden);
      sessionStorage.setItem(STORAGE_KEY, encodedSitesHidden);
    }
  },
});

export default useHiddenSiteStore;

export { useHiddenSiteStore };
