import { defineStore } from "pinia";

const STORAGE_KEY = "SystemHealthPercentageThreshold";

const usePercentageThresholdStore = defineStore("percentageThreshold", {
  state: () => ({
    percentageValue: 50,
  }),
  actions: {
    populateFromStorage() {
        let storageValue = sessionStorage.getItem(STORAGE_KEY);
        if (storageValue === "undefined")
        {
          sessionStorage.setItem(STORAGE_KEY, JSON.stringify(50));
        }

        this.percentageValue = JSON.parse(sessionStorage.getItem(STORAGE_KEY));

    }
  },
});

const watchStore = usePercentageThresholdStore();
watchStore.$subscribe((_, state) => {
  sessionStorage.setItem(STORAGE_KEY, JSON.stringify(state.percentageValue));
})

export default usePercentageThresholdStore;

export { usePercentageThresholdStore };
